import React from 'react'

const TitleName = () => {
  return (
    <div className='frame'>
    <div className="frame__content">
      <h2>ITAC INDUSTRY</h2>
    </div>
  </div>
  )
}

export default TitleName